import React from "react";

const AboutBox = () => {
 return (
    <div className="about__boxes grid">
        <div className="about__box">
            <i class="about__icon icon-user"></i>

            <div>
                <h3 className="about__title">13</h3>
                    <span className="about__subtitle">Afsluttede projekter inden for vand og vandrensning</span>
            </div>
        </div>

        <div className="about__box">
        <i class="about__icon icon-badge"></i>

            <div>
                <h3 className="about__title">100+</h3>
                    <span className="about__subtitle">Vandundersøgelser </span>
            </div>
        </div>

        <div className="about__box">
        <i class="about__icon icon-badge"></i>

            <div>
                <h3 className="about__title">50</h3>
                    <span className="about__subtitle">Tilsyn</span>
            </div>
        </div>

        <div className="about__box">
        <i class="about__icon icon-bubble"></i>

            <div>
                <h3 className="about__title">100+</h3>
                    <span className="about__subtitle">Jeg har arbejdet dagligt med kvalitetssikring siden 2013 også inden for ISO 9001</span>
            </div>
        </div>

    </div>
 )
}


export default AboutBox