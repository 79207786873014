import React from "react";
import "./home.css";

import Logo from "../../assets/logo2.png";
import HeaderSocials from "./HeaderSocials";
import ScrollDown from "./ScrollDown";




const Home = () => {
    return (
        <section className="home container" id="home">
            <div className="intro">
                <img src={Logo} alt="" className="home__img" />
              
               <span className="home__education">Vi rådgiver om alt indenfor drikkevandsforsyning </span><br></br>
              
       

              <HeaderSocials /> 
               <a href="#contact" className="btn">Kontakt os</a>
               <ScrollDown />
            </div>
        </section>
    )
}

export default Home