import React from "react";
import { useState } from "react";
import "./portfolio.css";
import Menu from "./Menu";



const Portfolio = () => {
  const [items, setItems] = useState(Menu);
  const filterItem = (categoryItem) => {
    const updatedItems = Menu.filter((curElem) => {
      return curElem.category === categoryItem;
    });

    setItems(updatedItems);
  };
  return (
    <section className="work container section" id="work">
      <h2 className="section__title">Tjenester</h2>

      <div className="work__filters">
         <span className="work__item" onClick={() => setItems(Menu)}>
          Alt
        </span>
        <span className="work__item" onClick={() => filterItem("vandværker")}>
          For Vandværker
        </span>
        <span className="work__item" onClick={() => filterItem("for kommuner og vandværker")}>
          For kommuner og vandværker
        </span>
      </div>

      <div className="work__container grid">
        {items.map((elem) => {
          const { id, image, title, description } = elem;
          return (
            <div className="work__card" key={id}>
              <div className="work__thumbnail">
                <img src={image} alt="" className="services__img" />
              </div>
              <h3 className="services__title">{title}</h3>
              <p className="services__description">{description}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
