import React from "react";
import "./about.css";
import Image from "../../assets/avatar-2.svg";
import AboutBox from "./AboutBox";

const About = () => {
  return (
    <section className="about container section" id="about">
      <h2 className="section__title">Oplysninger om os</h2>
      <div className="about__container grid"></div>
   { /* <img src={Image} alt="" className="about__img" />  */ }

      <div className="about__data grid">
        <div className="about__info">
          <p className="about__description">
          Vi hjælper med at vurderer behovet for etablering af ny boring kontra overboring af den gamle.<br />
I forbindelse med ny boring kan vi udføre lovpligtigt boretilsyn samt indmåle placeringen af boringen.  <br />
Er der problemer med de tekniske installationer på vandværket eks.: 
Boringer, Filtrering, Beluftning, Udpumpning, Tryk, Ledningsnet og Vandmålere kan vi hjælpe med at løse problemet. 


   </p>
   { /*      <a href="#btn" className="btn">
            download CV
          </a>
          */ }
        </div>

        
        <div className="about__skills grid">
          <div className="skills__data">
            <div className="skills__titles">
              <h3 className="skills__name">Vandanalyse</h3>
              <span className="skills__number">90%</span>
            </div>

            <div className="skills__bar">
              <span className="skills__percentage vandanalyse"></span>
            </div>
          </div>

          <div className="skills__data">
            <div className="skills__titles">
              <h3 className="skills__name">Lovgivning </h3>
              <span className="skills__number">80%</span>
            </div>

            <div className="skills__bar">
              <span className="skills__percentage lovgivning"></span>
            </div>
          </div>

          <div className="skills__data">
            <div className="skills__titles">
              <h3 className="skills__name">Tilsyn</h3>
              <span className="skills__number">90%</span>
            </div>

            <div className="skills__bar">
              <span className="skills__percentage tilsyn"></span>
              
            </div>
          </div>
        </div>


      </div>
      <AboutBox />
    </section>
  );
};

export default About;
