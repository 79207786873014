import React from 'react'; 

    const Data = [
      {
        id: 1,
        category: "education",
        icon: "icon-graduation",
        year: "2020 - 2022",
        title: "Erhvervsakademiuddannelse som Miljøteknolog",
        desc: "Planlægning og udførelse af miljøforbedringer i virksomheder. Anvendelse af metoder til rensning af spildevand, jord og luft og optimere affaldsbehandling og -håndtering.",
      },
        {
          id: 2,
          category: "education",
          icon: "icon-graduation",
          year: "1984 - nu",
          title: "Smed ",
          desc: "Uddannelse som Smed: erfaring i fremstilling af stålkonstruktioner og maskindele til mange formål inden for industri, byggeri og landbrug.",
        },
       

        {
          id: 4,
          category: "experience",
          icon: "icon-briefcase",
          year: "2014 - present",
          title: "Kvalitetssikring",
          desc: "Erfaring inden for kvalitetsstyring og dokumentationskrav ISO 9001 mv.",
        },
        {
          id: 5,
          category: "experience",
          icon: "icon-briefcase",
          year: "2005 - nu",
          title: "Erfaring ved grundvand",
          desc: "Erfaring inden for grundvandsindvinding samt grundvandsbeskyttelse.",
        },
        {
          id: 6,
          category: "experience",
          icon: "icon-briefcase",
          year: "1984 - nu",
          title: "Teknisk erfaring",
          desc: "Teknisk erfaring inden for opbygning af og drift af vandværker.",
        },
      ];



export default Data