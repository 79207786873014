import React  from "react";
import "./testimonials.css";
import Image1 from "../../assets/profile.png"
import Image2 from "../../assets/Building-round.png"

import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

 

const data = [
    {
      id: 1,
      image: Image1,
      title: "Lars Schrøder",
      subtitle: "Sammen med Cowi",
      comment:
        "In-situ jordrensnings projekt på Åbygasværk",
    },
    {
      id: 2,
      image: Image1,
      title: "John Wester",
      subtitle: "sammen med AA Service",
      comment:
        "Vandrensnings projekt på Birkendegård",
    },
    {
      id: 3,
      image: Image2,
      title: "Skovgårde Vandforsyning",
      subtitle: "",
      comment:
        "Filter projektering",
    },
    {
      id: 4,
      image: Image1,
      title: "Ole Sørensen",
      subtitle: "Sammen med WSP",
      comment:
        "Drikkevandsboringer projekter ved Frijsenborgt og -TEM undersøgelser",
    },
    {
      id: 5,
      image: Image2,
      title: "Fjellerup Strands Vandværk ",
      subtitle: "sammen med AA Service",
      comment:
        "Borings renoveringer",
    },
    {
      id: 6,
      image: Image2,
      title: "Fløjstrup Smedie",
      subtitle: "",
      comment:
        "Renovering af private Vandværker",
    },
    {
      id: 7,
      image: Image2,
      title: "SP Brøndboring",
      comment:
        "Borings renoveringer",
    },

  ];

const Testimonials = () => {
return (
    <section className="testimonials container section" id="testimonials">
        <h2 className="section__title">Referencer</h2>

        <Swiper className="testimonals__container grid"
          // install Swiper modules
          modules={[Pagination]}
          spaceBetween={30}
          slidesPerView={1}
          grabCursor={true}
          pagination={{ clickable: true }}

        >
        
            {data.map(({id, image, title, subtitle, comment}) => {
                return (
                    <SwiperSlide className="testimonial__item" key={id}>
                        <div className="thumb">
                            <img src={image} alt="" />
                        </div>
                        <h3 className="testimonials__title">{title}</h3>
                        <span className="subtitle">{subtitle}</span>
                        <div className="comment">{comment}</div>
                    </SwiperSlide>
                )
            })}
            </Swiper>
    </section>
)
}

export default Testimonials