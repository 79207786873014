import React from "react";
import "./App.css";
import Sidebar from "./components/sidebar/Sidebar";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Resume from "./components/resume/Resume";
import Portfolio from "./components/portfolio/Portfolio";
import Testimonials from "./components/testimonials/Testimonials";
import Contact from "./components/contact/Contact";
import ScriptTag from "./water";
import { Helmet } from "react-helmet";

const Demo = (props) => <ScriptTag type="text/javascript" src="water.js" />;

const App = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vanddoktoren</title>
        <link
          rel="icon"
          type="image/png"
          href="../public/favicon.ico"
          sizes="16x16"
        />
      </Helmet>
      <Sidebar />
      <main className="main">
        <Home />
        <About />
        <Portfolio />
        <Resume />
        <Testimonials />
        <Contact />
      </main>
    </>
  );
};

export default App;
