import Image1 from "../../assets/water-bottle.png";
import Image2 from "../../assets/drill.png";
import Image3 from "../../assets/microscope.png";
import Image4 from "../../assets/financial-calculation.png";
import Image5 from "../../assets/analyse.png";
import Image6 from "../../assets/analytics-presentation.png";
import Image7 from "../../assets/holiday.png";
import Image8 from "../../assets/mapping.png";
import Image9 from "../../assets/to-do-list.png";
import Image10 from "../../assets/to-do-list.png";

const Menu = [


  {
    id: 1,
    image: Image1,
    title: "Bekæmpelse af forurening",
    category: "vandværker",
    description:
        "I forbindelse med forurening af drikkevand, kan vi hjælpe med kildeopsporing, udarbejdelse af indsatsplan samt bekæmpelse af forurening.",
  },


  {
    id: 2,
    image: Image2,
    title: "Boretilsyn",
    category: "for kommuner og vandværker",
    description: "I forbindelse med ny boring kan vi udføre lovpligtigt boretilsyn samt indmåle placeringen af boringen.",
    },

  {
    id: 3,
    image: Image3,
    title: "Prøvetagning og analyse af vand og jord",
    category: "vandværker",
    description: "Vi fortager også prøvetagning og analyse af vand og jord ved forurening, eller formodning herom.",
  },
  
  {
    id: 4,
    image: Image4,
    title: "Tryktabsberegninger og energi regnskab",
    category: "for kommuner og vandværker",
    description:"Vi fortager tryktabsberegninger ved dimensionering af pumper og ledninger og vi opstiller energi regnskab for ”energiforbrug pr. udpumpet m3”.",
  },
  
  
  {
    id: 5,
    image: Image5,
    title: "Vandindvendingstilladelser, Prøvetagningsplaner og Ledelsessystemer",
    category: "for kommuner og vandværker",
    description: "Vi er behjælpelige ved ansøgning af Vandindvendingstilladelser og udarbejde Prøvetagningsplaner og Ledelsessystemer",
  },
  
  {
    id: 6,
    image: Image6,
    title: "Oplæg",
    category: "for kommuner og vandværker",
    description:"Vi kommer gerne ud og holder et oplæg om sikring af rent drikkevand."
  },
  
  {
    id: 7,
    image: Image7,
    title: "Sygdom og ferie",
    category: "vandværker",
    description:"Vi drifter jeres vandværk ved sygdom og ferie.",
  },

  {
    id: 8,
    image: Image8,
    title: "Ajourføring af ledningsnet",
    category: "vandværker",
    description:"Vi kan hjælpe med ajourføring af ledningsnet.",
  },

  {
    id: 9,
    image: Image9,
    title: "Regulativer, Bekendtgørelser og Beredskabsplaner.",
    category: "vandværker",
    description:"Vi kan udarbejde: Regulativer, Bekendtgørelser samt Beredskabsplaner.",
  },

  {
    id: 10,
    image: Image10,
    title: "lovpligtig kommunalt tilsyn med det tekniske anlæg.",
    category: "for kommuner og vandværker",
    description:"Efter drikkevandsbekendtgørelsens (BEK 1383 af 03-10-2022)  kapitel 5 §27",
  },





];

export default Menu;
