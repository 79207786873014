import React from "react";
import "./contact.css";
import emailjs from '@emailjs/browser';
import { useState } from "react";

const Result =() =>{
    return(
        <p>Your Message has been sent. I will contact you soon.</p>
    )
}


function Contact (props) {
    const [result, showResult] = useState(false);
 const sendEmail = (e) => {
        e.preventDefault();

    
        emailjs.sendForm('service_m8eboje', 'template_2lv1k7e', e.target, 'feglpkv514Ul3Qcry')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          }
        );
        e.target.reset();
        showResult(true)
      };

      // hide result
      setTimeout(() =>{
        showResult(false);
      },5000);



    return (
       <section className="contact container section" id='contact'>
       <h2 className="section__title">Kontakt</h2>
       <div className="contact__container grid">
        <div className="contact__info">
            <h3 className="contact__title">Lad os tale om det!</h3>
            <p className="contact__details">Hej!👋 Kan du ikke lide formularer? <br />Send mig en mail: eb@VandDoktoren.dk <br />eller ring +45 2022 3099</p>
        </div>

        <form action="" onSubmit={sendEmail} className="contact__form">
            <div className="contact__form-group">
                <div className="contact__form-div">
                    <input type="text" name='user_name' className="contact__form-input" placeholder="Indsæt dit navn"/>
                </div>

                <div className="contact__form-div">
                    <input 
                    type="email" 
                    name="user_email" 
                    className="contact__form-input" 
                    placeholder="Indsæt din email"/>
                </div>

                <div className="contact__form-div">
                    <input type="text" name='subject' className="contact__form-input" placeholder="Insæt dit emne"/>
                </div>

                <div className="contact__form-div contact__form-area">
                    <textarea name="message" id="" cols="30" rows="10" className="contact__form-input" placeholder="Skriv din besked"></textarea>
                </div>
  
               <button type="submit" className="btn">Send Message</button>
               <div clasName="row">{
               result ? <Result /> : null} </div>
               </div>
        </form> 
        </div>
       </section>
    )
}

export default Contact